import React, { useMemo, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _commodity, _time, _user } from 'std';
import {
    Badge,
    Checkbox,
    colors,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Tooltip,
    Typography,
    withStyles,
    withTheme
} from '@material-ui/core';

import { useState } from 'react';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import { TableRow, TableCell, Paper, Grid, Menu, IconButton, Icon, Button, TextField } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import MultiSelect from 'components/InputComponents/MultiSelect';
import HttpContext from 'utils/contexts/HttpContext';
import { openWindow } from 'helpers/windowHelper';
import {
    convertToImportName,
    deviceHelper,
    getStateAbbreviationFromFullName,
    getStateFullNameFromAbbreviation,
    isAUSRegion
} from 'utils/misc';
import RecurringFeesDialog from 'components/RecurringFeesDialog';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { loc } from 'localizations/localizationHandler';
import ApproveRequestDialog from './Components/ApproveRequestDialog';
import RemovePickupWarningDialog from './Components/RemovePickupWarningDialog';
import { AUS_STATES_FULL_NAME } from '../../../constants';
import ManageDeliveriesDialog from './Components/ManageDeliveriesDialog';
import FakeLink from 'components/FakeLink/FakeLink';

const styles = theme => ({
    badgeOk: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    badgeError: {
        backgroundColor: colors.red[500],
        color: 'white'
    }
});

const problemAssetHeaders = [
    { name: 'Actions', key: null },
    { name: 'Warning', key: 'warning' },
    { name: 'Name', key: 'customer.name.first' },
    { name: 'Next Pickup Address', key: 'customer.location.description' },
    { name: 'Next Pickup Assets', key: null },
    { name: 'Subscriptions at Address', key: 'subscriptions' },
    { name: 'Last Service Date', key: 'date' },
    { name: 'Next Service Date', key: 'nextDate' },
    { name: 'Code', key: 'customer.uniqueID' },
    { name: 'Phone', key: 'customer.phone' },
    { name: 'Email', key: 'customer.email' }
];

const pendingRequestHeaders = [
    { name: 'Actions', key: null },
    { name: 'Name', key: 'customer.name.first' },
    { name: 'Address', key: 'customer.location.description' },
    { name: 'Requested Assets', key: 'commodities' },
    // { name: 'Request Date', key: 'createdAt' },
    { name: 'Code', key: 'customer.uniqueID' },
    { name: 'Phone', key: 'customer.phone' },
    { name: 'Email', key: 'customer.email' }
];

const activeAssetHeaders = [
    { name: 'Actions', key: null },
    { name: 'Name', key: 'customer.name.first' },
    { name: 'Address', key: 'customer.location.description' },
    { name: 'Assets', key: null },
    { name: 'Subscriptions', key: 'subscriptions' },
    { name: 'Last Service Date', key: 'date' },
    { name: 'Next Service Date', key: 'nextDate' },
    { name: 'Code', key: 'customer.uniqueID' },
    { name: 'Phone', key: 'customer.phone' },
    { name: 'Email', key: 'customer.email' }
];

function AssetTracking({ theme, history, operator, classes, setRefreshProblemAssetCount }) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));

    const [allTransporters, setAllTransporters] = useState([]);
    const [allDrivers, setAllDrivers] = useState([]);
    const [pendingStops, setPendingStops] = useState([]);
    const [commodities, setCommodities] = useState([]);
    const allTrackedCommodities = useMemo(() => {
        return commodities.reduce((trackedCommodities, commodity) => {
            let commodityList = [];
            if (commodity.enableCustomerOrders) {
                commodityList.push(commodity);
            }
            return [...trackedCommodities, ...commodityList];
        }, []);
    }, [commodities]);
    const allTrackedSubCommodities = useMemo(() => {
        return commodities.reduce((trackedCommodities, commodity) => {
            let subCommodityList = _.filter(
                _.get(commodity, 'subCommodities', []),
                subCommodity => subCommodity.enableCustomerOrders
            );
            return [...trackedCommodities, ...subCommodityList];
        }, []);
    }, [commodities]);

    const [filtersOpen, setFiltersOpen] = useState(null);
    const [fees, setFees] = useState([]);
    const [subcommodityFilter, setSubCommodityFilter] = useState([]);
    const [stateFilter, setStateFilter] = useState(AUS_STATES_FULL_NAME);

    //recurring fees dialog
    const [recurringFeesDialogOpen, setRecurringFeesDialogOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedPickups, setSelectedPickups] = useState([]);
    const [recurringFeesNote, setRecurringFeesNote] = useState(null);
    const [recommendedSubscriptions, setRecommendedSubscriptions] = useState([]);

    //reject pending requests
    const [checkedPendingRequests, setCheckedPendingRequests] = useState([]);
    const [showRejectReasonDialog, setShowRejectReasonDialog] = useState(false);
    const [pendingRequestsToReject, setPendingRequestsToReject] = useState([]);
    const [rejectReason, setRejectReason] = useState('');

    //manage deliveries dialog
    const [manageDeliveriesDialogOpen, setManageDeliveriesDialogOpen] = useState(false);
    const [pendingStopsAtLocation, setPendingStopsAtLocation] = useState([]);
    const [location, setLocation] = useState(null);
    const [manageDeliveriesNote, setManageDeliveriesNote] = useState(null);
    const [requestedCommodities, setRequestedCommodities] = useState([]);

    // //approve request dialog
    // const [selectedRequest, setSelectedRequest] = useState(false);
    // const [showApproveRequestDialog, setShowApproveRequestDialog] = useState(false);

    //clear pickup warnings
    const [checkedPickups, setCheckedPickups] = useState([]);
    const [showClearPickupWarningsDialog, setShowClearPickupWarningsDialog] = useState(false);
    const [pickupsToReview, setPickupsToReview] = useState([]);

    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const {
        loading: problemAssetsLoading,
        getData: getProblemAssets,
        response: problemAssetsResponse,
        page: problemAssetsPage,
        rowsPerPage: problemAssetsRowsPerPage,
        filteredData: filteredProblemAssets,
        search: problemAssetsSearch,
        filteredHeaders: filteredProblemAssetsHeaders,
        totalDataEntries: totalProblemAssetsEntries,
        handleChange: handleChangeProblemAssets,
        handleSearch: handleSearchProblemAssets,
        handleChangeFilterHeaders: handleChangeProblemAssetsHeaders,
        handleAllFilterHeaders: handleAllProblemAssetsHeaders,
        handleChangePage: handleChangeProblemAssetsPage,
        handleChangeRowsPerPage: handleChangeProblemAssetsRowsPerPage
    } = useTable({
        url: `/pickups/problemAssets?subCommodities=${subcommodityFilter}&states=${stateFilter}`,
        key: 'assetTrackingEntries',
        // url: `/pickups/problemAssets?subCommodities=${subcommodityFilter}`,
        // key: 'problemAssets',
        headers: problemAssetHeaders.map(h => h.name)
    });

    const {
        loading: pendingRequestsLoading,
        getData: getPendingRequests,
        response: pendingRequestsResponse,
        page: pendingRequestsPage,
        rowsPerPage: pendingRequestsRowsPerPage,
        filteredData: filteredPendingRequests,
        search: pendingRequestsSearch,
        filteredHeaders: filteredPendingRequestsHeaders,
        totalDataEntries: totalPendingRequestsEntries,
        handleChange: handleChangePendingRequests,
        handleSearch: handleSearchPendingRequests,
        handleChangeFilterHeaders: handleChangePendingRequestsHeaders,
        handleAllFilterHeaders: handleAllPendingRequestsHeaders,
        handleChangePage: handleChangePendingRequestsPage,
        handleChangeRowsPerPage: handleChangePendingRequestsRowsPerPage
    } = useTable({
        url: `/commodityRequests/pending?subCommodities=${subcommodityFilter}&states=${stateFilter}`,
        key: 'commodityRequests',
        headers: pendingRequestHeaders.map(h => h.name)
    });

    const {
        loading: activeAssetsLoading,
        getData: getActiveAssets,
        response: activeAssetsResponse,
        page: activeAssetsPage,
        rowsPerPage: activeAssetsRowsPerPage,
        filteredData: filteredActiveAssets,
        search: activeAssetsSearch,
        filteredHeaders: filteredActiveAssetsHeaders,
        totalDataEntries: totalActiveAssetsEntries,
        handleChange: handleChangeActiveAssets,
        handleSearch: handleSearchActiveAssets,
        handleChangeFilterHeaders: handleChangeActiveAssetsHeaders,
        handleAllFilterHeaders: handleAllActiveAssetsHeaders,
        handleChangePage: handleChangeActiveAssetsPage,
        handleChangeRowsPerPage: handleChangeActiveAssetsRowsPerPage
    } = useTable({
        url: `/pickups/activeAssets?subCommodities=${subcommodityFilter}&states=${stateFilter}`,
        key: 'assetTrackingEntries',
        headers: activeAssetHeaders.map(h => h.name)
    });

    const onViewAccount = customerId => {
        const URL = `/customers/${customerId}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleRecurringFeesDialog = row => () => {
        if (!row) {
            setRecurringFeesDialogOpen(false);
            setSelectedCustomer(null);
            setRecurringFeesNote(null);
            setRecommendedSubscriptions([]);
            setSelectedPickups([]);
        } else {
            let customer = _.cloneDeep(row.customer);
            _.get(customer, 'recurringFees', []).forEach(fee => {
                const populatedFee = _.find(fees, f => f._id === fee.fee);
                fee.fee = populatedFee;
            });
            setSelectedCustomer(customer);
            setSelectedPickups([
                { ...row, 'location.description': _.get(row, 'address', _.get(row, 'customer.location.description')) }
            ]);
            if (!_.isNil(row.commodities) && !_.isEmpty(row.commodities)) {
                setRecurringFeesNote(
                    getRequestedCommoditiesList(
                        row.commodities,
                        allTrackedCommodities,
                        allTrackedSubCommodities,
                        theme,
                        customer,
                        onViewAccount
                    )
                );
                setRecommendedSubscriptions(
                    getRecommendedSubscriptions(
                        row.commodities,
                        row.location,
                        fees,
                        allTrackedCommodities,
                        allTrackedSubCommodities
                    )
                );
            } else {
                setRecurringFeesNote(null);
                setRecommendedSubscriptions([]);
            }
            setRecurringFeesDialogOpen(true);
        }
    };

    const handleManageDeliveriesDialog = (row, stops) => () => {
        if (!row) {
            setManageDeliveriesDialogOpen(false);
            setSelectedCustomer(null);
            setLocation(null);
            setPendingStopsAtLocation([]);
            setManageDeliveriesNote(null);
            setRequestedCommodities([]);
        } else {
            let customer = _.cloneDeep(row.customer);
            _.get(customer, 'recurringFees', []).forEach(fee => {
                const populatedFee = _.find(fees, f => f._id === fee.fee);
                fee.fee = populatedFee;
            });
            if (!_.isNil(row.commodities) && !_.isEmpty(row.commodities)) {
                setManageDeliveriesNote(
                    getRequestedCommoditiesList(
                        row.commodities,
                        allTrackedCommodities,
                        allTrackedSubCommodities,
                        theme,
                        customer,
                        onViewAccount
                    )
                );
                setRequestedCommodities(row.commodities);
            } else {
                setRequestedCommodities([]);
                setManageDeliveriesNote(null);
            }
            setLocation(_.get(row, 'location', _.get(customer, 'location')));
            setSelectedCustomer(customer);
            setPendingStopsAtLocation(stops);
            setManageDeliveriesDialogOpen(true);
        }
    };

    function handlePendingCheckbox() {
        if (
            checkedPendingRequests.length === totalPendingRequestsEntries ||
            checkedPendingRequests.length === pendingRequestsRowsPerPage
        ) {
            setCheckedPendingRequests([]);
        } else {
            let newCheckedPendingRequests = Array.from(filteredPendingRequests, request => {
                return request._id;
            });
            setCheckedPendingRequests(newCheckedPendingRequests);
        }
    }

    const handleRejectPendingRequests = async () => {
        const res = await http.post('/commodityRequests/reject', {
            commodityRequestIds: pendingRequestsToReject,
            rejectReason
        });
        if (res.ok) {
            setTimeout(() => {
                getPendingRequests();
            }, 50);
            onSnackbar('Commodity request(s) rejected.');
            setCheckedPendingRequests([]);
            setPendingRequestsToReject([]);
            setShowRejectReasonDialog(false);
            setRejectReason('');
        } else {
            onSnackbar('Unable to reject commodity request(s)', 'error');
        }
    };

    const handleApprovePendingRequest = _id => async () => {
        const res = await http.post('/commodityRequests/' + _id + '/approve', {});
        if (res.ok) {
            setTimeout(() => {
                getPendingRequests();
            }, 50);
            onSnackbar('Commodity request approved.');
        } else {
            onSnackbar('Unable to approve commodity request', 'error');
        }
    };

    const handleRejectDialog = bottleDriveIds => {
        setPendingRequestsToReject(bottleDriveIds);
        setShowRejectReasonDialog(true);
    };

    function handleWarningCheckboxAll() {
        if (checkedPickups.length === pickupsToReview.length) {
            setCheckedPickups([]);
        } else {
            setCheckedPickups(pickupsToReview.map(p => p._id));
        }
    }

    const handleWarningCheckbox = _id => () => {
        let checkedPickupsCopy = _.cloneDeep(checkedPickups);
        if (checkedPickups.includes(_id)) {
            _.remove(checkedPickupsCopy, p => p.toString() === _id.toString());
        } else {
            checkedPickupsCopy.push(_id);
        }
        setCheckedPickups(checkedPickupsCopy);
    };

    const handleClearPickupWarnings = async () => {
        const res = await http.post('/pickups/clearAssetWarnings', {
            pickup_ids: checkedPickups
        });
        if (res.ok) {
            setTimeout(() => {
                getPendingRequests();
            }, 50);
            onSnackbar('Pickups updated');
            setShowClearPickupWarningsDialog(false);
            setCheckedPickups([]);
            setPickupsToReview([]);
            setSelectedCustomer(null);
            getProblemAssets();
            getActiveAssets();
        } else {
            onSnackbar('Unable to remove warnings', 'error');
        }
    };

    const renderRows = (
        row,
        filteredHeaders,
        key,
        actions,
        checkbox = false,
        checkedList = [],
        setCheckedList = () => {}
    ) => {
        const cellStyle = { whiteSpace: 'nowrap' };
        const subscriptions = {};
        if (!_.isNil(_.get(row, 'customer.recurringFees'))) {
            _.get(row, 'customer.recurringFees', [])
                .filter(
                    fee =>
                        _.isNil(_.get(row, 'address')) ||
                        fee.address === _.get(row, 'address', '') ||
                        _.isNil(fee.address) ||
                        _.isEmpty(fee.address)
                )
                .forEach(fee => {
                    _.set(
                        subscriptions,
                        _.get(fee, 'fee', 'Fee').trim(),
                        _.get(subscriptions, _.get(fee, 'fee', 'Fee').trim(), 0) + 1
                    );
                });
        }
        const reducedPayload = _.cloneDeep(row.payload);
        if (!_.isNil(reducedPayload)) {
            _commodity.removeSubPayloadsFromPayload(reducedPayload, _.get(row, 'subPayloads', []));
        }

        const outOfSystemStopsForLocation = _.filter(
            pendingStops,
            stop =>
                _.get(stop, 'location.place_id', '') === _.get(row, '_id.place_id', '') ||
                _.get(stop, 'location.description', '') === _.get(row, 'location.description', '')
        );
        return (
            <TableRow key={row._id}>
                {checkbox && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={checkedList.includes(row._id)}
                            onChange={e => {
                                if (!e.target.checked) {
                                    setCheckedList(
                                        checkedList.filter(element => {
                                            return element !== row._id;
                                        })
                                    );
                                } else {
                                    setCheckedList([...checkedList, row._id]);
                                }
                            }}
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ minWidth: 150, whiteSpace: 'noWrap' }}>
                        {actions.includes('view_account') && (
                            <Tooltip title="View Customer Account" disableFocusListener disableTouchListener>
                                <IconButton
                                    onClick={() => {
                                        onViewAccount(_.get(row, 'customer._id'));
                                    }}
                                >
                                    <Badge
                                        badgeContent={
                                            _.filter(_.get(row, 'upcomingPickups', []), p => !p.unserviced).length
                                        }
                                        classes={{
                                            badge:
                                                _.filter(_.get(row, 'upcomingPickups', []), p => !p.unserviced).length >
                                                0
                                                    ? classes.badgeOk
                                                    : classes.badgeError
                                        }}
                                        showZero
                                    >
                                        <Icon>visibility</Icon>
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        )}
                        {actions.includes('manage_subscriptions') && (
                            <Tooltip title="Manage Subscriptions" disableFocusListener disableTouchListener>
                                <span>
                                    <IconButton onClick={handleRecurringFeesDialog(row)}>
                                        <Badge
                                            badgeContent={_.get(row, 'customer.recurringFees', []).length}
                                            classes={{
                                                badge:
                                                    _.get(row, 'customer.recurringFees', []).length > 0
                                                        ? classes.badgeOk
                                                        : classes.badgeError
                                            }}
                                            showZero
                                        >
                                            <Icon>event_repeat</Icon>
                                        </Badge>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                        {actions.includes('manage_bookings') && (
                            <Tooltip title="Manage Deliveries" disableFocusListener disableTouchListener>
                                <span>
                                    <IconButton
                                        onClick={handleManageDeliveriesDialog(row, outOfSystemStopsForLocation)}
                                    >
                                        <Badge
                                            badgeContent={outOfSystemStopsForLocation.length}
                                            classes={{
                                                badge: classes.badgeOk
                                            }}
                                        >
                                            <Icon>local_shipping</Icon>
                                        </Badge>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                        {actions.includes('approve_request') && (
                            <Tooltip title="Approve">
                                <IconButton onClick={handleApprovePendingRequest(row._id)}>
                                    <Icon>check</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        {actions.includes('reject_request') && (
                            <Tooltip title="Reject">
                                <IconButton
                                    onClick={() => {
                                        handleRejectDialog([row._id]);
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        {/* {actions.includes('clear_warning') && !_.isEmpty(_.get(row, 'problemPickups', [])) && (
                            <Tooltip title="Mark as Resolved">
                                <IconButton
                                    onClick={() => {
                                        setPickupsToReview(row.problemPickups);
                                        setShowClearPickupWarningsDialog(true);
                                        setSelectedCustomer(row.customer);
                                    }}
                                >
                                    <Icon>{_.get(row, 'problemPickups', []).length > 1 ? 'done_all' : 'check'}</Icon>
                                </IconButton>
                            </Tooltip>
                        )} */}
                    </TableCell>
                )}
                {filteredHeaders.includes('Warning') && (
                    <TableCell style={cellStyle}>{_.get(row, 'warning', 'N/A')}</TableCell>
                )}
                {filteredHeaders.includes('Name') && (
                    <TableCell style={cellStyle}>
                        {_.get(
                            row,
                            'customer.registeredBusinessName',
                            _.get(row, 'customer.businessName', _user.getNameFull(_.get(row, 'customer')))
                        )}
                    </TableCell>
                )}
                {(filteredHeaders.includes('Address') || filteredHeaders.includes('Next Pickup Address')) && (
                    <TableCell style={cellStyle}>
                        {_.get(row, 'address', _.get(row, 'location.description', 'N/A'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Requested Assets') && (
                    <TableCell style={cellStyle}>
                        {_.get(row, 'commodities', []).map(item => {
                            const commodity = _.find(
                                item.isSubCommodity ? allTrackedSubCommodities : allTrackedCommodities,
                                c => c._id.toString() === item._id.toString()
                            );
                            if (_.isNil(commodity)) {
                                return <></>;
                            }
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: theme.spacing.unit }}>
                                        <MDIcon
                                            path={_.get(
                                                allIcons,
                                                _.startsWith(commodity.iconName, 'mdi')
                                                    ? commodity.iconName
                                                    : convertToImportName(commodity.iconName)
                                            )}
                                            size={1}
                                            color={commodity.color}
                                        />
                                    </span>
                                    {_.get(commodity, item.isSubCommodity ? 'name.en' : 'skuType', 'Item')} x{' '}
                                    {item.amount}
                                </div>
                            );
                        })}
                    </TableCell>
                )}
                {(filteredHeaders.includes('Assets') || filteredHeaders.includes('Next Pickup Assets')) && (
                    <TableCell style={cellStyle}>
                        {_.keys(reducedPayload || {}).map(sku => {
                            const commodity = _.find(
                                allTrackedCommodities,
                                commodity => commodity.skuType.toString() === sku.toString()
                            );
                            if (_.isNil(commodity) || reducedPayload[sku] === 0) {
                                return <></>;
                            }
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: theme.spacing.unit }}>
                                        <MDIcon
                                            path={_.get(
                                                allIcons,
                                                _.startsWith(commodity.iconName, 'mdi')
                                                    ? commodity.iconName
                                                    : convertToImportName(commodity.iconName)
                                            )}
                                            size={1}
                                            color={commodity.color}
                                        />
                                    </span>
                                    {_.get(commodity, 'payloadInputName.en', 'N/A')} x {reducedPayload[sku]}
                                </div>
                            );
                        })}

                        {(_.get(row, 'subPayloads', []) || []).map(item => {
                            const subCommodity = _.find(
                                allTrackedSubCommodities,
                                subCommodity => subCommodity._id === item.subCommodity
                            );
                            if (_.isNil(subCommodity) || item.amount === 0) {
                                return <></>;
                            }
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: theme.spacing.unit }}>
                                        <MDIcon
                                            path={_.get(
                                                allIcons,
                                                _.startsWith(subCommodity.iconName, 'mdi')
                                                    ? subCommodity.iconName
                                                    : convertToImportName(subCommodity.iconName)
                                            )}
                                            size={1}
                                            color={subCommodity.color}
                                        />
                                    </span>
                                    {_.get(subCommodity, 'name.en', 'N/A')} x {item.amount}
                                </div>
                            );
                        })}
                    </TableCell>
                )}
                {(filteredHeaders.includes('Subscriptions') ||
                    filteredHeaders.includes('Subscriptions at Address')) && (
                    <TableCell style={cellStyle}>
                        {_.keys(subscriptions).map(fee_id => (
                            <div style={{ display: 'flex', verticalAlign: 'center' }}>
                                {_.get(_.find(fees, f => f._id.toString() === fee_id.toString()), 'description', 'Fee')}
                                {subscriptions[fee_id] > 1 ? ` x ${subscriptions[fee_id]}` : ''}
                            </div>
                        ))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Last Service Date') && (
                    <TableCell style={cellStyle}>
                        {_.isNil(_.get(row, 'previousPickup.date'))
                            ? 'N/A'
                            : formatDate(_.get(row, 'previousPickup.date'), timezone)}
                    </TableCell>
                )}
                {filteredHeaders.includes('Next Service Date') && (
                    <TableCell style={cellStyle}>
                        {_.isNil(_.get(row, 'date')) ? 'N/A' : formatDate(_.get(row, 'date'), timezone)}
                    </TableCell>
                )}
                {filteredHeaders.includes('Code') && (
                    <TableCell style={cellStyle}>{_.get(row, 'customer.uniqueID', 'N/A')}</TableCell>
                )}
                {filteredHeaders.includes('Phone') && (
                    <TableCell style={cellStyle}>
                        {_.isNil(_.get(row, 'phoneOverride'))
                            ? _.get(row, 'customer.phone', 'N/A')
                            : _.get(row, 'phoneOverride', 'N/A')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Email') && (
                    <TableCell style={cellStyle}>{_.get(row, 'customer.email', 'N/A')}</TableCell>
                )}
            </TableRow>
        );
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const handleChange = e => {
        handleChangeActiveAssets(e);
        handleChangePendingRequests(e);
        handleChangeProblemAssets(e);
    };

    const handleSearch = e => {
        handleSearchProblemAssets(e);
        handleSearchPendingRequests(e);
        handleSearchActiveAssets(e);
    };

    const handleSubmit = e => {
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const reloadStops = async () => {
        const res = await http.getJSON('/stops/allPending');
        if (res.ok) {
            setPendingStops(_.get(res, 'data.collectionData', []));
            if (manageDeliveriesDialogOpen) {
                setPendingStopsAtLocation(
                    _.filter(
                        _.get(res, 'data.collectionData', []),
                        stop => _.get(stop, 'location.place_id') === location.place_id
                    )
                );
            }
        }
    };

    const reloadCollectors = async () => {
        const res = await http.getJSON('/collectors');
        if (res.ok) {
            const allCollectors = _.get(res, 'data.collectors', []);
            setAllTransporters(
                _.filter(allCollectors, c => _.get(c, 'configuration.enablePickups', true) && !c.disabled)
            );
        }
    };

    useEffect(() => {
        (async () => {
            const [resCommodities, resCollectors, resDrivers, resFees, resStops] = await Promise.all([
                http.getJSON('/commodities/getAllCommodities'),
                http.getJSON('/collectors'),
                http.getJSON('/users/getAllDrivers'),
                http.getJSON('/fees/getAllFees'),
                http.getJSON('/stops/allPending')
            ]);
            if (resCommodities.ok) {
                setCommodities(_.get(resCommodities, 'data.collectionData', []));
            }
            if (resCollectors.ok) {
                const allCollectors = _.get(resCollectors, 'data.collectors', []);
                setAllTransporters(
                    _.filter(allCollectors, c => _.get(c, 'configuration.enablePickups', true) && !c.disabled)
                );
            }
            if (resDrivers.ok) {
                setAllDrivers(_.get(resDrivers, 'data.drivers', []));
            }
            if (resFees.ok) {
                setFees(_.get(resFees, 'data.collectionData', []));
            }
            if (resStops.ok) {
                setPendingStops(_.get(resStops, 'data.collectionData', []));
            }
        })();
    }, []);

    useEffect(() => {
        setSubCommodityFilter([
            ...allTrackedSubCommodities.map(subCommodity => subCommodity._id),
            ...allTrackedCommodities.map(commodity => commodity._id)
        ]);
    }, [allTrackedSubCommodities, allTrackedCommodities]);

    useEffect(() => {
        if (!_.isNil(selectedCustomer) && recurringFeesDialogOpen) {
            let row = _.find(filteredActiveAssets, row => {
                return _.get(row, 'customer._id', '').toString() === _.get(selectedCustomer, '_id', '').toString();
            });
            if (_.isNil(row)) {
                row = _.find(filteredProblemAssets, row => {
                    return _.get(row, 'customer._id', '').toString() === _.get(selectedCustomer, '_id', '').toString();
                });
            }
            if (_.isNil(row)) {
                setSelectedCustomer(null);
                setSelectedPickups([]);
                setRecurringFeesDialogOpen(false);
                return;
            }
            let customer = _.cloneDeep(row.customer);
            _.get(customer, 'recurringFees', []).forEach(fee => {
                const populatedFee = _.find(fees, f => f._id === fee.fee);
                fee.fee = populatedFee;
            });
            setSelectedCustomer(customer);
            setSelectedPickups([
                { ...row, 'location.description': _.get(row, 'address', _.get(row, 'customer.location.description')) }
            ]);
        }
    }, [filteredActiveAssets, filteredProblemAssets]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit}>
                                    {renderSearch(
                                        <>
                                            <>
                                                {isAUSRegion() && (
                                                    <MultiSelect
                                                        variant="outlined"
                                                        selectedValues={stateFilter}
                                                        selectableValues={AUS_STATES_FULL_NAME.map(s => {
                                                            return {
                                                                name: getStateAbbreviationFromFullName(s),
                                                                value: s
                                                            };
                                                        })}
                                                        onChange={value => setStateFilter(value)}
                                                        style={{ marginRight: theme.spacing.unit }}
                                                        renderValue={v => `${v.length} States`}
                                                        renderMenuItem={({ name, value, obj }) => {
                                                            return (
                                                                <MenuItem key={value} value={value}>
                                                                    {name}
                                                                </MenuItem>
                                                            );
                                                        }}
                                                    />
                                                )}
                                                <MultiSelect
                                                    variant="outlined"
                                                    selectedValues={subcommodityFilter}
                                                    selectableValues={[
                                                        ...allTrackedSubCommodities.map(subCommodity => ({
                                                            name: _.get(subCommodity, 'name.en'),
                                                            value: subCommodity._id,
                                                            obj: {
                                                                ...subCommodity,
                                                                iconName: convertToImportName(
                                                                    _.get(subCommodity, 'iconName')
                                                                )
                                                            }
                                                        })),
                                                        ...allTrackedCommodities.map(commodity => ({
                                                            name: _.get(commodity, 'payloadInputName.en'),
                                                            value: commodity._id,
                                                            obj: commodity
                                                        }))
                                                    ]}
                                                    onChange={value => setSubCommodityFilter(value)}
                                                    style={{ marginRight: theme.spacing.unit }}
                                                    renderValue={v => `${v.length} Commodity Types`}
                                                    renderMenuItem={({ name, value, obj }) => {
                                                        return (
                                                            <MenuItem key={value} value={value}>
                                                                <span style={{ marginRight: theme.spacing.unit }}>
                                                                    <MDIcon
                                                                        path={_.get(allIcons, obj.iconName)}
                                                                        size={1}
                                                                        color={obj.color}
                                                                    />
                                                                </span>
                                                                {name}
                                                            </MenuItem>
                                                        );
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={activeAssetsSearch || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={
                                                            activeAssetsLoading ||
                                                            pendingRequestsLoading ||
                                                            problemAssetsLoading
                                                        }
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            {filteredPendingRequests.length > 0 && (
                <DashBoardTable
                    checkbox
                    handleCheckbox={handlePendingCheckbox}
                    checkedItems={checkedPendingRequests.length}
                    customActionAlign="left"
                    customAction={
                        <Button
                            data-cy="reject"
                            variant="contained"
                            color="secondary"
                            disabled={checkedPendingRequests.length === 0}
                            style={{}}
                            onClick={e => {
                                e.preventDefault();
                                handleRejectDialog(checkedPendingRequests);
                            }}
                        >
                            Reject
                        </Button>
                    }
                    customActionStyle={{ paddingTop: 0 }}
                    title="New Bin Requests"
                    loading={pendingRequestsLoading}
                    headers={pendingRequestHeaders}
                    filteredHeaders={filteredPendingRequestsHeaders}
                    length={filteredPendingRequests.length}
                    page={pendingRequestsPage}
                    rowsPerPage={pendingRequestsRowsPerPage}
                    handleChangePage={handleChangePendingRequestsPage}
                    handleChangeRowsPerPage={handleChangePendingRequestsRowsPerPage}
                    refresh={getPendingRequests}
                >
                    {filteredPendingRequests &&
                        filteredPendingRequests.map((row, key) => {
                            return renderRows(
                                row,
                                filteredPendingRequestsHeaders,
                                key,
                                [
                                    'view_account',
                                    'manage_subscriptions',
                                    'manage_bookings',
                                    'approve_request',
                                    'reject_request'
                                ],
                                true,
                                checkedPendingRequests,
                                setCheckedPendingRequests
                            );
                        })}
                </DashBoardTable>
            )}
            {filteredProblemAssets.length > 0 && (
                <DashBoardTable
                    title="Problem Assets"
                    loading={problemAssetsLoading}
                    headers={problemAssetHeaders}
                    filteredHeaders={filteredProblemAssetsHeaders}
                    length={totalProblemAssetsEntries}
                    page={problemAssetsPage}
                    rowsPerPage={problemAssetsRowsPerPage}
                    handleChangePage={handleChangeProblemAssetsPage}
                    handleChangeRowsPerPage={handleChangeProblemAssetsRowsPerPage}
                    refresh={getProblemAssets}
                >
                    {filteredProblemAssets &&
                        filteredProblemAssets.map((row, key) => {
                            return renderRows(row, filteredProblemAssetsHeaders, key, [
                                'view_account',
                                'manage_subscriptions',
                                'manage_bookings'
                            ]);
                        })}
                </DashBoardTable>
            )}

            <DashBoardTable
                title="Active Assets"
                loading={activeAssetsLoading}
                headers={activeAssetHeaders}
                filteredHeaders={filteredActiveAssetsHeaders}
                length={totalActiveAssetsEntries}
                page={activeAssetsPage}
                rowsPerPage={activeAssetsRowsPerPage}
                handleChangePage={handleChangeActiveAssetsPage}
                handleChangeRowsPerPage={handleChangeActiveAssetsRowsPerPage}
                refresh={getActiveAssets}
            >
                {filteredActiveAssets &&
                    filteredActiveAssets.map((row, key) => {
                        return renderRows(row, filteredActiveAssetsHeaders, key, [
                            'view_account',
                            'manage_subscriptions',
                            'manage_bookings'
                        ]);
                    })}
            </DashBoardTable>

            {recurringFeesDialogOpen && (
                <RecurringFeesDialog
                    http={http}
                    pickups={selectedPickups}
                    customer={selectedCustomer}
                    open={recurringFeesDialogOpen}
                    auth={operator}
                    reloadCustomer={() => {
                        getPendingRequests();
                        getActiveAssets();
                        getProblemAssets();
                        setRefreshProblemAssetCount(true);
                    }}
                    onClose={handleRecurringFeesDialog(false)}
                    note={recurringFeesNote}
                    onSnackbar={onSnackbar}
                    recommendedSubscriptions={recommendedSubscriptions}
                />
            )}

            {manageDeliveriesDialogOpen && (
                <ManageDeliveriesDialog
                    http={http}
                    pendingStops={pendingStopsAtLocation}
                    customer={selectedCustomer}
                    open={manageDeliveriesDialogOpen}
                    auth={operator}
                    reload={() => {
                        getPendingRequests();
                        getActiveAssets();
                        getProblemAssets();
                        reloadStops();
                        reloadCollectors();
                    }}
                    onClose={handleManageDeliveriesDialog(false)}
                    onSnackbar={onSnackbar}
                    transporters={allTransporters}
                    location={location}
                    commodities={commodities}
                    note={manageDeliveriesNote}
                    handleView={onViewAccount}
                    requestedCommodities={requestedCommodities}
                />
            )}

            {/* <ApproveRequestDialog
                open={showApproveRequestDialog}
                theme={theme}
                selectedRequest={selectedRequest}
                onClose={() => {
                    setShowApproveRequestDialog(false);
                    setSelectedRequest(null);
                }}
                http={http}
                onSnackbar={onSnackbar}
                transporters={allTransporters}
                receivers={allDrivers}
                handleView={onViewAccount}
                afterSubmitSuccess={() => {
                    setTimeout(() => {
                        getPendingRequests();
                        getActiveAssets();
                        getProblemAssets();
                    }, 50);
                    setShowApproveRequestDialog(false);
                    setSelectedRequest(null);
                    onSnackbar('Successfully applied subscriptions and scheduled commodity drop off');
                }}
                commodities={allTrackedCommodities}
                subCommodities={allTrackedSubCommodities}
                fees={fees}
            /> */}

            <Dialog open={showRejectReasonDialog}>
                <DialogContent>
                    <Typography variant="h6">
                        Reason for Rejecting
                        {pendingRequestsToReject.length > 1 && ` ${pendingRequestsToReject.length} Pending Requests`}
                    </Typography>
                    <TextField
                        data-cy="pending-request-warning-dialog-reason-input"
                        label={`Reason`}
                        error={!rejectReason}
                        value={rejectReason}
                        onChange={e => setRejectReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        helperText="Customer will see this text."
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="default" size="small" onClick={() => setShowRejectReasonDialog(false)}>
                        Nevermind
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        disabled={!rejectReason}
                        onClick={() => handleRejectPendingRequests()}
                        data-cy="pending-request-warning-dialog-confirm"
                    >
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <RemovePickupWarningDialog
                open={showClearPickupWarningsDialog}
                pickups={pickupsToReview}
                checkedPickups={checkedPickups}
                theme={theme}
                onClose={() => {
                    setShowClearPickupWarningsDialog(false);
                    setCheckedPickups([]);
                    setPickupsToReview([]);
                    setSelectedCustomer(null);

                    setRefreshProblemAssetCount(true);
                }}
                onSubmit={handleClearPickupWarnings}
                handleCheckbox={handleWarningCheckbox}
                handleAllCheckbox={handleWarningCheckboxAll}
                formatDate={formatDate}
                allTrackedCommodities={allTrackedCommodities}
                allTrackedSubCommodities={allTrackedSubCommodities}
                customer={selectedCustomer}
                fees={fees}
            /> */}
        </>
    );
}

export default withStyles(styles)(withTheme()(AssetTracking));

function formatDate(date, timezone) {
    if (_.isNil(date)) {
        return '';
    } else {
        return moment(date)
            .tz(timezone)
            .format('MMM D, YYYY');
    }
}

function getRequestedCommoditiesList(
    requestedCommodities,
    commodities,
    subCommodities,
    theme,
    customer,
    onViewAccount
) {
    if (_.isNil(requestedCommodities) || _.isEmpty(requestedCommodities)) return <></>;
    return (
        <>
            <FakeLink
                theme={theme}
                text={`${_.get(customer, 'name.first', 'N/A')} ${_.get(customer, 'name.last', '')}`}
                onClickEvent={() => onViewAccount(customer._id)}
                color={theme.palette.primary.main}
                data-cy="view-customer"
            />{' '}
            has requested:
            <div style={{ display: 'flex' }}>
                {requestedCommodities.map((item, i) => {
                    const commodity = _.find(
                        item.isSubCommodity ? subCommodities : commodities,
                        c => c._id.toString() === item._id.toString()
                    );
                    if (_.isNil(commodity)) {
                        return <></>;
                    }
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                verticalAlign: 'middle',
                                whiteSpace: 'noWrap'
                            }}
                        >
                            <span style={{ marginRight: theme.spacing.unit }}>
                                <MDIcon
                                    path={_.get(
                                        allIcons,
                                        _.startsWith(commodity.iconName, 'mdi')
                                            ? commodity.iconName
                                            : convertToImportName(commodity.iconName)
                                    )}
                                    size={1}
                                    color={commodity.color}
                                />
                            </span>
                            <Typography variant="body2" style={{ marginRight: theme.spacing.unit }}>
                                {_.get(commodity, item.isSubCommodity ? 'name.en' : 'skutype', 'Item')} x {item.amount}
                                {i < requestedCommodities.length - 1 && ', '}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

function getRecommendedSubscriptions(requestedCommodities, location, fees, commodities, subCommodities) {
    const recommendedSubscriptions = [];
    (requestedCommodities || []).forEach((item, i) => {
        const commodity = _.find(
            item.isSubCommodity ? subCommodities : commodities,
            c => c._id.toString() === item._id.toString()
        );
        if (!_.isNil(commodity)) {
            const fee = _.find(fees, fee => fee._id.toString() === commodity.subscriptionFee.toString());
            if (!_.isNil(fee)) {
                recommendedSubscriptions.push({
                    fee,
                    quantity: item.amount,
                    location: location,
                    frequency: commodity.subscriptionFeeRecurrence
                });
            }
        }
    });
    return recommendedSubscriptions;
}
